import { HTTP_STATUS_CODE_NO_CONTENT, HTTP_STATUS_CODE_OK } from "raci-react-library";
import { EnvironmentHealthStatusButton, SystemHealthInformation, bffVersionAtom } from "raci-react-library-npe";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useBffApiClient } from "../../shared/hooks/useApiClient";

export const EnvironmentHealthStatus = () => {
  const initialHealthState: SystemHealthInformation = {};
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [healthStatus, setHealthStatus] = useState(initialHealthState);
  const setBffVersion = useSetRecoilState(bffVersionAtom);
  const apiClient = useBffApiClient();

  useEffect(() => {
    let isCancelled = false;

    const getStatus = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        if (!isCancelled) {
          const response = await apiClient.gethealthstatus();
          if (response.status !== HTTP_STATUS_CODE_OK && response.status !== HTTP_STATUS_CODE_NO_CONTENT) {
            setIsError(true);
          } else {
            if (response.status === HTTP_STATUS_CODE_OK) {
              setHealthStatus(response.result);
              if (response.result.version) {
                setBffVersion({
                  version: response.result.version.split("+")[0],
                  buildId: response.result.version.split("+")[1],
                });
              }
            }
          }
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getStatus();

    return () => {
      isCancelled = true;
    };
  }, [apiClient, setBffVersion]);

  return (
    <EnvironmentHealthStatusButton systemHealthInformation={healthStatus} isLoading={isLoading} isError={isError} />
  );
};

export default EnvironmentHealthStatus;
