import { useContext } from "react";
import { ClaimNumberContext } from "../../components/ClaimNumberProvider";

export const useClaimNumber = () => {
  const claimNumber = useContext(ClaimNumberContext);

  if (claimNumber === undefined) {
    throw new Error("useClaimNumber must be used within a ClaimNumberProvider");
  }

  return claimNumber;
};

export default useClaimNumber;
