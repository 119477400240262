import { lazy } from "react";
import PreForm from "../../views/UploadInvoice/PreForm";
import { RouteInformation } from "./routes.config";

const UploadAndSubmit = lazy(() => import("../../views/UploadInvoice/UploadAndSubmit"));
const Confirmation = lazy(() => import("../../views/UploadInvoice/Confirmation"));
const LinkExpired = lazy(() => import("../../views/UploadInvoice/LinkExpired"));
const FileCountExceeded = lazy(() => import("../../views/UploadInvoice/FileCountExceeded"));
const SessionTimeout = lazy(() => import("../../views/UploadInvoice/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/UploadInvoice/SystemUnavailable"));

export const UPLOAD_INVOICE_SUB_ROUTE = "invoice-or-quote";
export const UPLOAD_INVOICE_TITLE = "Upload your documents";

export enum UploadInvoicePreFormRoute {
  PreForm = "",
}

export enum UploadInvoiceFormRoute {
  UploadAndSubmit = "upload-and-submit",
  Confirmation = "confirmation",
}

export enum UploadInvoiceErrorRoute {
  FileCountExceeded = "file-limit",
  LinkExpired = "link-expired",
  SessionTimeout = "408",
  SystemUnavailable = "error",
}

export enum UploadInvoicePageName {
  PreForm = "",
  UploadAndSubmit = "Upload and submit",
  Confirmation = "Confirmation",
  LinkExpired = "Link expired",
  FileCountExceeded = "File limit",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
}

export enum UploadInvoicePageHeading {
  UploadAndSubmit = "Upload and submit",
  LinkExpired = "Uh oh!",
  FileCountExceeded = "File limit reached",
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
}

type UploadInvoiceRoute = UploadInvoicePreFormRoute | UploadInvoiceFormRoute | UploadInvoiceErrorRoute;

const getUploadInvoiceSubPath = (route?: UploadInvoiceRoute) =>
  `/${UPLOAD_INVOICE_SUB_ROUTE}${route ? `/${route}` : ""}`;

export const getUploadInvoicePathname = (route?: UploadInvoiceRoute) =>
  `${process.env.REACT_APP_HOME_PAGE}${getUploadInvoiceSubPath(route)}`;

export const getUploadInvoiceUrl = (route?: UploadInvoiceRoute) =>
  `${process.env.PUBLIC_URL}${getUploadInvoiceSubPath(route)}`;

export const UPLOAD_INVOICE_PRE_FORM_ROUTE_KEY = "preform";

const uploadInvoicePreFormRoutesInfo: Record<UploadInvoicePreFormRoute, RouteInformation> = {
  [UploadInvoicePreFormRoute.PreForm]: {
    key: UPLOAD_INVOICE_PRE_FORM_ROUTE_KEY,
    path: getUploadInvoiceUrl(UploadInvoicePreFormRoute.PreForm),
    name: UploadInvoicePageName.PreForm,
    element: <PreForm />,
  },
};

const uploadInvoiceFormRoutesInfo: Record<UploadInvoiceFormRoute, RouteInformation> = {
  [UploadInvoiceFormRoute.UploadAndSubmit]: {
    key: UploadInvoiceFormRoute.UploadAndSubmit,
    path: getUploadInvoiceUrl(UploadInvoiceFormRoute.UploadAndSubmit),
    name: UploadInvoicePageName.UploadAndSubmit,
    element: <UploadAndSubmit />,
    heading: UploadInvoicePageHeading.UploadAndSubmit,
  },
  [UploadInvoiceFormRoute.Confirmation]: {
    key: UploadInvoiceFormRoute.Confirmation,
    path: getUploadInvoiceUrl(UploadInvoiceFormRoute.Confirmation),
    name: UploadInvoicePageName.Confirmation,
    element: <Confirmation />,
  },
};

export const uploadInvoiceErrorRoutesInfo: Record<UploadInvoiceErrorRoute, RouteInformation> = {
  [UploadInvoiceErrorRoute.FileCountExceeded]: {
    key: UploadInvoiceErrorRoute.FileCountExceeded,
    path: getUploadInvoiceUrl(UploadInvoiceErrorRoute.FileCountExceeded),
    name: UploadInvoicePageName.FileCountExceeded,
    element: <FileCountExceeded />,
    heading: UploadInvoicePageHeading.FileCountExceeded,
  },
  [UploadInvoiceErrorRoute.LinkExpired]: {
    key: UploadInvoiceErrorRoute.LinkExpired,
    path: getUploadInvoiceUrl(UploadInvoiceErrorRoute.LinkExpired),
    name: UploadInvoicePageName.LinkExpired,
    element: <LinkExpired />,
    heading: UploadInvoicePageHeading.LinkExpired,
  },
  [UploadInvoiceErrorRoute.SessionTimeout]: {
    key: UploadInvoiceErrorRoute.SessionTimeout,
    path: getUploadInvoiceUrl(UploadInvoiceErrorRoute.SessionTimeout),
    name: UploadInvoicePageName.SessionTimeout,
    element: <SessionTimeout />,
    heading: UploadInvoicePageHeading.SessionTimeout,
  },
  [UploadInvoiceErrorRoute.SystemUnavailable]: {
    key: UploadInvoiceErrorRoute.SystemUnavailable,
    path: getUploadInvoiceUrl(UploadInvoiceErrorRoute.SystemUnavailable),
    name: UploadInvoicePageName.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UploadInvoicePageHeading.SystemUnavailable,
  },
};

export const uploadInvoicePreFormRoutes: RouteInformation[] = Object.values(uploadInvoicePreFormRoutesInfo);
export const uploadInvoiceFormRoutes: RouteInformation[] = Object.values(uploadInvoiceFormRoutesInfo);
export const uploadInvoiceErrorRoutes: RouteInformation[] = Object.values(uploadInvoiceErrorRoutesInfo);

export const uploadInvoiceRoutes = [
  ...uploadInvoicePreFormRoutes,
  ...uploadInvoiceFormRoutes,
  ...uploadInvoiceErrorRoutes,
];
