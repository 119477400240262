import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UploadInvoiceFormRoute,
  UploadInvoicePreFormRoute,
  getUploadInvoicePathname,
  getUploadInvoiceUrl,
} from "../uploadInvoiceRoutes.config";
import useRoutes from "../useRoutes";

export interface RouteGuardProps {
  disableChecks?: string[];
}

export const RouteGuard = ({ disableChecks = [] }: RouteGuardProps) => {
  const location = useLocation();
  const { navigateToFirstStep, isUploadInvoiceFlowCompleted, isPreFormPageCompleted } = useRoutes();
  const navigate = useNavigate();
  const [navigatedOnEntry, setNavigatedOnEntry] = useState(false);
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();

  const currentPathname = location.pathname.toLowerCase();
  const bypass = disableChecks.filter((item) => item.toLowerCase() === currentPathname).length > 0;

  // UPLOAD INVOICE FLOW: /claims/servicing/invoice-or-quote/*
  useEffect(() => {
    if (bypass) {
      return;
    }

    if (currentPathname.startsWith(getUploadInvoicePathname())) {
      if (isUploadInvoiceFlowCompleted) {
        if (currentPathname !== getUploadInvoicePathname(UploadInvoiceFormRoute.Confirmation)) {
          navigate(getUploadInvoiceUrl(UploadInvoiceFormRoute.Confirmation), { replace: true });
        }
      } else if (
        (currentPathname === getUploadInvoicePathname() || currentPathname === `${getUploadInvoicePathname()}/`) &&
        isPreFormPageCompleted
      ) {
        navigate(getUploadInvoiceUrl(UploadInvoiceFormRoute.UploadAndSubmit), { replace: true });
      } else {
        if (
          previousPageUrl === undefined &&
          currentPathname !== getUploadInvoicePathname(UploadInvoicePreFormRoute.PreForm) &&
          navigatedOnEntry === false
        ) {
          setNavigatedOnEntry(true);
          navigateToFirstStep();
        }
        if (!bypass && previousPageUrl && !isPreviousPageCompleted) {
          navigate(previousPageUrl, { replace: true });
        }
      }
    }
  }, [
    navigate,
    disableChecks,
    location.pathname,
    isPreviousPageCompleted,
    previousPageUrl,
    navigatedOnEntry,
    navigateToFirstStep,
    isUploadInvoiceFlowCompleted,
    isPreFormPageCompleted,
    currentPathname,
    bypass,
  ]);

  // OTHER FLOWS: /claims/servicing/*
  useEffect(() => {
    if (bypass) {
      return;
    }

    if (!currentPathname.startsWith(getUploadInvoicePathname())) {
      if (!bypass && previousPageUrl === undefined && navigatedOnEntry === false) {
        setNavigatedOnEntry(true);
        navigateToFirstStep();
      }
      if (previousPageUrl && !isPreviousPageCompleted) {
        navigate(previousPageUrl, { replace: true });
      }
    }
  }, [
    navigate,
    disableChecks,
    previousPageUrl,
    isPreviousPageCompleted,
    location.pathname,
    navigatedOnEntry,
    navigateToFirstStep,
    currentPathname,
    bypass,
  ]);

  return <></>;
};

export default RouteGuard;
