import { useSessionStatePrefix } from "raci-react-library";
import { createContext, ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { STORAGE_KEY_CLAIM_NUMBER } from "../../constants";

export const ClaimNumberContext = createContext<string | null | undefined>(undefined);

export const ClaimNumberProvider = ({ children }: { children: ReactNode }) => {
  const sessionStatePrefix = useSessionStatePrefix();
  const [searchParams] = useSearchParams();
  const queryParamClaimNumber = searchParams.get("claimNumber");

  const claimNumberStorageKey = `${sessionStatePrefix}${STORAGE_KEY_CLAIM_NUMBER}`;
  const sessionStorageClaimNumber = sessionStorage.getItem(claimNumberStorageKey);

  useEffect(() => {
    queryParamClaimNumber && sessionStorage.setItem(claimNumberStorageKey, queryParamClaimNumber ?? "");
  }, [queryParamClaimNumber, claimNumberStorageKey]);

  return (
    <ClaimNumberContext.Provider value={queryParamClaimNumber ?? sessionStorageClaimNumber}>
      {children}
    </ClaimNumberContext.Provider>
  );
};

export default ClaimNumberProvider;
