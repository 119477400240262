import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UPLOAD_INVOICE_TITLE, getUploadInvoiceUrl } from "../uploadInvoiceRoutes.config";
import useRoutes from "../useRoutes";

const defaultTitle = process.env.REACT_APP_SITE_TITLE || "";
const envPrefix = process.env.REACT_APP_ENVIRONMENT !== "PRD" ? `**${process.env.REACT_APP_ENVIRONMENT}** - ` : "";

const createPageTitle = (routeTitle: string, page: string) => {
  return `${envPrefix}Claims ${routeTitle ? ` - ${routeTitle}` : ""} ${page ? ` - ${page}` : ""}`;
};

const getRouteTitle = (location: string) => {
  if (location.startsWith(getUploadInvoiceUrl())) {
    return UPLOAD_INVOICE_TITLE;
  } else {
    return "";
  }
};

export const TitleGuard = () => {
  const location = useLocation();
  const { allRoutes } = useRoutes();

  useEffect(() => {
    if (document) {
      const currentLocation = location.pathname.toLowerCase();
      const route = allRoutes.find((item) => item.path === location.pathname);
      const routeTitle = getRouteTitle(currentLocation);
      document.title = route ? createPageTitle(routeTitle, route.name) : defaultTitle;
    }
  }, [location.pathname, allRoutes]);

  return <></>;
};
