import { RacwaBreadcrumbDetails } from "@racwa/react-components";
import { ClaimService } from "../../../routing/routes.config";

export enum Breadcrumb {
  UploadInvoice = "Upload documents",
}

const breadcrumbs: Record<ClaimService, RacwaBreadcrumbDetails> = {
  [ClaimService.UploadInvoice]: {
    links: [],
    currentPage: {
      key: Breadcrumb.UploadInvoice,
      name: Breadcrumb.UploadInvoice,
    },
  },
};

export const getBreadcrumbs = (claimService: ClaimService): RacwaBreadcrumbDetails => breadcrumbs[`${claimService}`];

export default getBreadcrumbs;
