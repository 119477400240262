import { useIsAuthenticated } from "@racwa/react-adb2c";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ADB2C_CLIENT_ID } from "../../constants";
import { uploadInvoiceErrorRoutes, uploadInvoicePreFormRoutes } from "../../routing/uploadInvoiceRoutes.config";
import { useBffApiClient } from "../useApiClient";

export const useGetWebChatInformation = () => {
  const apiClient = useBffApiClient();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated({ clientId: ADB2C_CLIENT_ID });
  const [getPointOfContactId, setPointOfContactId] = useState("");
  const [getShouldRenderWebChat, setShouldRenderWebChat] = useState(false);
  const [getCrmId, setCrmId] = useState("");
  const disallowPages = [
    ...uploadInvoicePreFormRoutes.map((route) => route.path),
    ...uploadInvoiceErrorRoutes.map((route) => route.path),
  ];
  const currentLocation = location.pathname.toLowerCase();

  const shouldGetWebChatInformation = isAuthenticated && !disallowPages?.includes(currentLocation);

  useEffect(() => {
    let isCancelled = false;

    const getWebChatId = async () => {
      try {
        if (!isCancelled) {
          if (shouldGetWebChatInformation) {
            const response = await apiClient.getwebchatcontactid();
            setPointOfContactId(response.result.pointOfContactId);
            setCrmId(response.result.crmId ?? "");
            setShouldRenderWebChat(true);
          } else {
            setShouldRenderWebChat(false);
          }
        }
      } catch {
        setPointOfContactId("");
        setShouldRenderWebChat(false);
      }
    };

    getWebChatId();

    return () => {
      isCancelled = true;
    };
  }, [apiClient, currentLocation, shouldGetWebChatInformation]);

  return { pointOfContactId: getPointOfContactId, crmId: getCrmId, shouldRender: getShouldRenderWebChat };
};

export default useGetWebChatInformation;
