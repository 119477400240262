import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export interface LogCustomProperties {
  location: string;
  attempts: string;
  timeTakenInSeconds?: string;
}

const applicationInsightsPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
    extensions: [applicationInsightsPlugin as any],
    disableAjaxTracking: true,
    disableFetchTracking: true,
    disableExceptionTracking: true,
  },
});

// If you need to test application insights locally
// set the connection string in the .env file
// it's undefined by default since it's loaded by the pipeline
if (process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING !== undefined) {
  appInsights.loadAppInsights();
}

export { applicationInsightsPlugin };
