import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaAuth } from "@racwa/react-adb2c";
import { RacwaThemeProvider } from "@racwa/react-components";
import {
  Backdrop,
  MyRacAuth,
  SessionStatePrefixProvider,
  SessionStatusProvider,
  SplashScreen,
  TrackPageChanges,
  WebChat,
} from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import ClaimNumberProvider from "./shared/components/ClaimNumberProvider";
import { SESSION_KEY_PREFIX } from "./shared/constants";
import useCreateSession from "./shared/hooks/useCreateSession";
import useGetWebChatInformation from "./shared/hooks/useGetWebChatInformation";
import { applicationInsightsPlugin } from "./shared/utils/applicationInsights";
import { getMyRacAuthProps, racwaAuthProps } from "./shared/utils/auth";
import Views from "./views";

export const App = () => (
  <RacwaAuth {...racwaAuthProps}>
    <RacwaThemeProvider>
      <RecoilRoot>
        <AppInsightsContext.Provider value={applicationInsightsPlugin}>
          <SplashScreen />
          <Backdrop />
          <BrowserRouter>
            <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
              <ClaimNumberProvider>
                <MyRacAuth {...getMyRacAuthProps()}>
                  <SessionStatusProvider useCreateSession={useCreateSession}>
                    <Views />
                  </SessionStatusProvider>
                  <WebChat useGetWebChatInformation={useGetWebChatInformation} />
                </MyRacAuth>
              </ClaimNumberProvider>
            </SessionStatePrefixProvider>
            <TrackPageChanges />
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </RecoilRoot>
    </RacwaThemeProvider>
  </RacwaAuth>
);

export default App;
