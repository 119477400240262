import {
  UPLOAD_INVOICE_TITLE,
  uploadInvoiceErrorRoutes,
  uploadInvoiceFormRoutes,
  uploadInvoicePreFormRoutes,
  uploadInvoiceRoutes,
} from "./uploadInvoiceRoutes.config";

export enum ClaimService {
  UploadInvoice,
}

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export interface ClaimServiceRouteInfo {
  allRoutes: RouteInformation[];
  preFormRoutes?: RouteInformation[];
  formRoutes: RouteInformation[];
  errorRoutes: RouteInformation[];
  sidebarTitle: string;
}

export const claimsServicingRouteInfo: Record<ClaimService, ClaimServiceRouteInfo> = {
  [ClaimService.UploadInvoice]: {
    allRoutes: uploadInvoiceRoutes,
    preFormRoutes: uploadInvoicePreFormRoutes,
    formRoutes: uploadInvoiceFormRoutes,
    errorRoutes: uploadInvoiceErrorRoutes,
    sidebarTitle: UPLOAD_INVOICE_TITLE,
  },
};
