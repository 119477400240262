import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useLocation, useNavigate } from "react-router-dom";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const sidebarContent = <SidebarContent feedbackUrl={process.env.REACT_APP_RAC_FEEDBACK_URL || EMPTY_URL} />;

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const commonPageProps = usePage();
  const { formRoutes, sidebarTitle, steps, activeStepIndex, canNavigateToPreviousStep, navigateToPreviousStep } =
    useRoutes();

  const contentTitle = formRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname.toLowerCase(),
  )?.heading;

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: () => canNavigateToPreviousStep && navigateToPreviousStep && navigateToPreviousStep(),
  };

  const onStepClick = (_: string, stepIndex: number) => {
    activeStepIndex !== stepIndex && canNavigateToPreviousStep && navigate(steps[`${stepIndex}`].path);
  };

  return {
    contentTitle,
    sidebarTitle,
    sidebarContent,
    steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
