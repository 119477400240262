import { useSessionState, useSessionStateInitialiser } from "raci-react-library";
import { useEffect, useMemo } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { PreFormState } from "../../../views/UploadInvoice/PreForm/types";
import { UploadAndSubmitState } from "../../../views/UploadInvoice/UploadAndSubmit/types";
import { ClaimService, ClaimServiceRouteInfo, claimsServicingRouteInfo } from "../routes.config";
import { UPLOAD_INVOICE_PRE_FORM_ROUTE_KEY, UploadInvoiceFormRoute } from "../uploadInvoiceRoutes.config";

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesReturn extends ClaimServiceRouteInfo {
  claimService: ClaimService;
  isActiveFlow: boolean;
  steps: StepInformation[];
  activeStepIndex?: number;
  canNavigateToPreviousStep: boolean;
  navigateToPreviousStep?: () => void;
  navigateToFirstStep: () => void;
  isUploadInvoiceFlowCompleted: boolean;
  isPreFormPageCompleted: boolean;
}

// TODO: determine which service's route information to return from current location
const getRouteInformation = (_: Location) => ({
  claimService: ClaimService.UploadInvoice,
  ...claimsServicingRouteInfo[ClaimService.UploadInvoice],
});

export const useRoutes = (): UseRoutesReturn => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialiseSessionStates = useSessionStateInitialiser();

  const { claimService, allRoutes, preFormRoutes, formRoutes, errorRoutes, sidebarTitle } =
    getRouteInformation(location);

  useEffect(() => {
    initialiseSessionStates(
      allRoutes.map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [allRoutes, initialiseSessionStates, location.pathname]);

  const isActiveFlow = formRoutes.some((route) => route.path === location.pathname);

  const [preFormState] = useSessionState<PreFormState>({ specificKey: UPLOAD_INVOICE_PRE_FORM_ROUTE_KEY });
  const [uploadAndSubmitState] = useSessionState<UploadAndSubmitState>({
    specificKey: UploadInvoiceFormRoute.UploadAndSubmit,
  });

  const steps: StepInformation[] = useMemo(() => {
    if (!isActiveFlow) return [];

    return formRoutes.reduce((filtered: StepInformation[], option) => {
      filtered.push({ name: option.name, path: option.path });
      return filtered;
    }, []);
  }, [formRoutes, isActiveFlow]);

  const activeStepIndex = isActiveFlow ? steps.findIndex((item) => item.path === location.pathname) : undefined;

  const navigateToFirstStep = useMemo(() => {
    return () => {
      const isErrorRoute = errorRoutes.findIndex((route) => route.path === location.pathname) >= 0;
      const preFormPage = preFormRoutes?.[0] ?? { path: "" };

      if (!isErrorRoute) {
        navigate(preFormPage.path, { replace: true });
      }
    };
  }, [errorRoutes, preFormRoutes, location.pathname, navigate]);

  const navigateToPreviousStep = useMemo(() => {
    return isActiveFlow && (activeStepIndex ?? 0) > 0
      ? () => navigate(steps[(activeStepIndex ?? 0) - 1].path, { replace: true })
      : undefined;
  }, [isActiveFlow, activeStepIndex, navigate, steps]);

  const canNavigateToPreviousStep = false;

  return {
    claimService,
    allRoutes,
    preFormRoutes,
    formRoutes,
    errorRoutes,
    isActiveFlow,
    sidebarTitle,
    steps,
    activeStepIndex,
    navigateToPreviousStep,
    navigateToFirstStep,
    canNavigateToPreviousStep,
    isUploadInvoiceFlowCompleted: uploadAndSubmitState.isCompleted ?? false,
    isPreFormPageCompleted: (preFormState.isCompleted && preFormState.isClaimValid) ?? false,
  };
};

export default useRoutes;
