import Form from "./Form";
import { usePreForm } from "./hooks/usePreForm";

export const PreForm = () => {
  usePreForm();

  return <Form />;
};

export default PreForm;
