export const SESSION_KEY_PREFIX = "RAC_CSE_";
export const NON_BREAKING_SPACE = "\u00a0";

export const STORAGE_KEY_CLAIM_NUMBER = "CLAIM_NUMBER";

export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const DOCUMENT_SERVICE_API_BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_API_BASE_URL ?? "";

export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";
export const ADB2C_RETURN_URL = process.env.REACT_APP_ADB2C_RETURN_URL ?? "";

export const PRODUCT_LINKING_REDIRECT_URL = process.env.REACT_APP_PRODUCT_LINKING_REDIRECT_URL ?? "";

export const SILENT_LOGIN_REDIRECT_URL = `${process.env.PUBLIC_URL}/redirect`;
