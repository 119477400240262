import { useFileUpload } from "raci-react-library";
import { useLocation, useNavigate } from "react-router-dom";
import { useDocumentServiceApiClient } from "../../../../shared/hooks/useApiClient";
import { UploadInvoiceErrorRoute, getUploadInvoiceUrl } from "../../../../shared/routing/uploadInvoiceRoutes.config";

export const useUploadInvoiceFileUpload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const documentServiceApiClient = useDocumentServiceApiClient();

  const onFileCountExceeded = () => navigate(getUploadInvoiceUrl(UploadInvoiceErrorRoute.FileCountExceeded));
  const onError = () =>
    navigate(getUploadInvoiceUrl(UploadInvoiceErrorRoute.SystemUnavailable), {
      state: {
        referrer: location.pathname,
      },
    });

  return useFileUpload(documentServiceApiClient, onFileCountExceeded, onError);
};

export const useUploadInvoiceFileUploadProps = () => {
  const { fetchConfiguration, ...fileUploadProps } = useUploadInvoiceFileUpload();

  return fileUploadProps;
};

export default useUploadInvoiceFileUpload;
